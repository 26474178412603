import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  formConfig,
  updateSkuLine,
  totalViewRefs,
  updateDamageImages,
  removeDamageImage,
  updateRate,
  errorMessage,
} from './config';
import Form from './Form';
import { TableView, TotalView } from './View';
import { DialogFormWrapper } from '../../../../common';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { filterItems, findAndReplaceItem } from '../../../../../utils/arrayProcessor';
import { handleGRNSKUBatchSelect, isConfirmationType } from '../../../../common/HelperFunctions';
import Confirmation from '../../../../common/DialogConfirmation';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import GRNFormStyled from './GRNFormStyled';
import { getUser } from '../../../../../data/services';

const propTypes = {
  update: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  skuList: PropTypes.instanceOf(Array),
  serverResponseWaiting: PropTypes.bool,
  getStatus: PropTypes.func,
  getDetails: PropTypes.func,
  imageUploader: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
  distributorBatchFlag: PropTypes.bool,
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  skuList: [],
  enableErrorDisplay: false,
  distributorBatchFlag: false,
  serverResponseWaiting: false,
  getStatus: () => null,
  getDetails: () => null,
  imageUploader: () => null,
};

class Table extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.distributorExist = this.userInfo.Distributor.length === 1;
    this.state = {
      data: {
        lines: [],
        amount: {},
      },
      dialog: {
        type: '',
        element: '',
      },
      skuBatchList: [],
      originalSkuBatchList: [],
      selectedSkuTags: [],
    };
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  handleFormSubmit = (type, dialogData, error) => {
    const { displayAlert } = this.props;
    if (type === EVENT_OPERATION.DELETE) {
      this.handleRowDelete(dialogData);
    } else if (!dialogData.batchName) {
      displayAlert(ALERT_TYPE.WARNING, 'Batch is missing');
    } else if (error === 'error') {
      displayAlert(
        ALERT_TYPE.WARNING,
        `Sum of BIN location total quantity and shortage quantity did not match with main quantity ${dialogData?.quantity}.`,
      );
    } else if (dialogData.warehouseLevelDetails.map(d => d.quantity).includes(undefined)) {
      displayAlert(ALERT_TYPE.WARNING, 'Quantity is missing');
    } else if (error === 'qtyError') {
      displayAlert(ALERT_TYPE.WARNING, `${errorMessage.quantityError} not exceed ${dialogData.quantity}.`);
    } else {
      this.handleDataUpdate(dialogData);
    }
  };

  handleDataUpdate = dialogData => {
    const images = this.getImages ? this.getImages() : [];
    const { data } = this.state;
    const modifiedDialogData = this.modifyData(dialogData, images);
    if (modifiedDialogData.id) {
      data.lines = findAndReplaceItem(data.lines, modifiedDialogData);
    } else {
      modifiedDialogData.id = 1000 + data.lines.length + 1;
      data.lines.push(modifiedDialogData);
    }
    this.setState({ data }, () => this.calculateLineTotal());
  };

  handleRowDelete = dialogData => {
    const { data } = this.state;
    const index = data.lines.findIndex(item => item.id === dialogData.id);
    data.lines.splice(index, 1);
    this.setState({ data }, () => this.calculateLineTotal());
  };

  modifyData = (dialogData, images) => {
    const { skuList } = this.props;
    const { skuBatchList } = this.state;
    const selectedSku = filterItems(skuList, dialogData.skuId)[0] || {};
    const selectedBatch = filterItems(skuBatchList, dialogData.skuBatchId)[0] || {};
    const updateDialogData = updateSkuLine(dialogData, selectedSku, selectedBatch);
    updateDialogData.damageImages = images;
    return updateDialogData;
  };

  handleQntyChange = (item, value, state, stateUpdater) => {
    const { displayAlert } = this.props;
    if (state.shortages + state.damages > state.quantity) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, `${errorMessage.quantityError} not exceed ${state.quantity}.`);
    }
  };

  handleIconClick = (type, element = {}) => {
    const { pendingStatus } = this.props;
    let rates = [];
    if (element.skuId) {
      const { skuList } = this.props;
      const selectedSku = filterItems(skuList, element.skuId)[0] || {};
      rates = selectedSku.Rates || [];
    }
    if (pendingStatus) {
      element.batchName = element.SkuBatch ? element.SkuBatch.batchName : element.batchName;
      element.expiryDate = element.SkuBatch.usageDate
        ? element.SkuBatch.usageDate.expiryDate
        : element.SkuBatch.expiryDate;
      element.manufactureDate = element.SkuBatch.usageDate
        ? element.SkuBatch.usageDate.manufactureDate
        : element.SkuBatch.manufactureDate;
    }
    this.setState({
      dialog: {
        type,
        element,
      },
      data: element,
      skuBatchList: pendingStatus ? [element.SkuBatch] : rates,
    });
  };

  onDropDownChange = (item, value, state, stateUpdater) => {
    const { skuList, getSKUBatchDetail, data, distributorSelectedId, distributorBatchFlag } = this.props;
    const { skuBatchList } = this.state;
    const distributorId = this.distributorExist ? data.details.Distributor.id : distributorSelectedId;
    if (item === 'skuId') {
      const selectedSku = filterItems(skuList, value)[0] || {};
      const { skuTags } = selectedSku;
      getSKUBatchDetail(
        { distributorId, skuId: value },
        {
          handleSuccess: response => {
            const batchDetails = response.data.getSkuBatchDetails;
            if (!distributorBatchFlag) {
              const batch = batchDetails.length > 0 ? batchDetails[0] : null;
              state.batchName = batch ? batch.batchName : 'DEFAULT_BATCH';
              state.skuBatchId = batch ? batch.id : 0;
              state.priceDetails.rate = batch ? batch.dlp : 0;
              stateUpdater(state);
            } else {
              state.batchName = '';
              state.skuBatchId = 0;
              state.priceDetails.rate = 0;
              stateUpdater(state);
            }
            this.setState({
              skuBatchList: batchDetails,
              originalSkuBatchList: batchDetails,
              selectedSkuTags: skuTags,
            });
          },
          handleError: err => {
            state.batchName = 'DEFAULT_BATCH';
            state.skuBatchId = 0;
            state.priceDetails.rate = 0;
            stateUpdater(state);
            console.log(err);
          },
        },
      );
    }
    if (item === 'skuBatchId') {
      state.skuBatchId = value.id;
      state.batchName = value.batchName;
      stateUpdater(state);
      handleGRNSKUBatchSelect(value.id, skuBatchList, state, stateUpdater, updateRate);
    }
  };

  inputCallBack = (item, value, state, stateUpdater) => {
    const { originalSkuBatchList } = this.state;
    const checkBatchNameAndGetList = state.batchName
      ? originalSkuBatchList.filter(d => d.batchName.toLowerCase().startsWith(state.batchName.toLowerCase()))
      : originalSkuBatchList;
    const checkValueInSKUBatch = originalSkuBatchList.find(d => d.batchName.toLowerCase() === value.toLowerCase());
    if (state.batchName === '' || checkValueInSKUBatch === undefined) {
      state.skuBatchId = 0;
      stateUpdater(state);
    }
    this.setState({
      skuBatchList: checkBatchNameAndGetList,
    });
  };

  /** todo configure payload and response as per image upload api * */

  uploadImage = (file, state, stateUpdater) => {
    const { imageUploader } = this.props;
    imageUploader(file, {
      successCallBack: response => {
        updateDamageImages(state, stateUpdater, response.imageURL);
      },
      failureCallBack: error => {
        /** *todo remove  updateDamageImage from here */
        updateDamageImages(state, stateUpdater, error.message);
        // displayAlert(ALERT_TYPE.DANGER, error)
      },
    });
  };

  handleDiscountChange = e => {
    const { data } = this.state;
    data.amount[e.target.name] = e.formattedValue;
    this.calculateTotal(data);
  };

  calculateTotal = data => {
    const taxableAmount = data.amount.subTotal - data.amount.billDiscount - data.amount.tradeDiscount;
    const vat = 0.13 * taxableAmount;
    data.amount.taxAmount = vat;
    data.amount.taxableAmount = taxableAmount;
    data.amount.total = taxableAmount + vat;
    this.setState({ data });
  };

  calculateLineTotal = () => {
    const { data } = this.state;
    let subTotal = 0;
    let exciseTotal = 0;
    data.lines.map(item => (subTotal += item.priceDetails.netAmount));
    data.lines.map(item => (exciseTotal += item.priceDetails.exciseAmount));
    data.amount.subTotal = subTotal;
    data.amount.exciseAmount = exciseTotal;
    this.calculateTotal(data);
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  getValidationStatus = () => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    if (data.lines.length > 0) {
      return !Object.values(totalViewRefs).find(item => item.getValidState() === false);
    }
    displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'No Sku lines added');
    return false;
  };
  exportData = () => {
    const { data } = this.state;
    return data;
  };

  render() {
    const { data, dialog, skuBatchList, selectedSkuTags } = this.state;
    const { type } = dialog;
    const {
      update,
      skuList,
      pendingStatus,
      enableErrorDisplay,
      serverResponseWaiting,
      distributorBatchFlag,
      exciseInLine,
    } = this.props;

    const skuLines = data.lines || [];
    const priceDetails = data.amount || {};
    return (
      <GRNFormStyled>
        <div className="return-create">
          {type && (
            <DialogFormWrapper
              formConfig={formConfig[type]}
              dialogElement={dialog.element}
              onDialogSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              withOutPadding
              popUpGrn
              approveGrn={update.type === EVENT_OPERATION.APPROVE}
              type={type}
              renderDialog={({
                refsObj,
                dialogData,
                handleInputChange,
                enableErrorDisplay,
                handleMultipleUpload,
                handleDropDownChange,
                updateState,
                getState,
              }) => (
                  <Fragment>
                    {(type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                      <Form
                        show
                        update={update}
                        refsObj={refsObj}
                        skuList={skuList}
                        data={dialogData}
                        skuBatchList={skuBatchList}
                        loading={serverResponseWaiting}
                        uploadCallBack={this.uploadImage}
                        getState={getState}
                        updateState={updateState}
                        onUploadRemove={removeDamageImage}
                        handleInputChange={handleInputChange}
                        enableErrorDisplay={enableErrorDisplay}
                        dropDownCallBack={this.onDropDownChange}
                        handleDropDownChange={handleDropDownChange}
                        handleMultipleUpload={handleMultipleUpload}
                        selectedSkuTags={selectedSkuTags}
                        distId={pendingStatus ? data.distributorId : data.details.Distributor.id}
                        inputCallBack={this.inputCallBack}
                        getImages={images => (this.getImages = images)}
                        distributorBatchFlag={distributorBatchFlag}
                        type={type}
                        pendingStatus={pendingStatus}
                        handleQntyChange={this.handleQntyChange}
                        exciseInLine={exciseInLine}
                      />
                    )}
                    {isConfirmationType(type) && Confirmation(type, 1)}
                  </Fragment>
                )}
            />
          )}
          <TableView
            update={update}
            skuLines={skuLines}
            onIconClick={this.handleIconClick}
            className={!update.status ? 'disabled' : ''}
            exciseInLine={exciseInLine}
          />
          <TotalView
            update={update}
            refsObj={totalViewRefs}
            priceDetails={priceDetails}
            enableErrorDisplay={enableErrorDisplay}
            onInputChange={this.handleDiscountChange}
          />
        </div>
      </GRNFormStyled>
    );
  }
}

Table.defaultProps = defaultProps;

Table.propTypes = propTypes;

export default withAlert()(Table);
