import styled from 'styled-components';

const SRNStyled = styled.div`
  .table-wrap.srn-details {
    overflow-x: auto;
    overflow-y: auto;
    min-height: 40vh;
    table {
      thead {
        th {
          position: unset !important;
        }
      }
    }
    .quantity {
      cursor: pointer;
    }
    .simple-popup {
      .filter-dropdown-card {
        right: -235px;
        top: unset;
        top: -13px;
        text-align: left;
        .filter-content {
          .arrow-left {
            top: 14px;
            left: -6px;
            right: unset;
          }
        }
      }
    }
    .react-tooltip {
      &__main {
        li {
          padding: 8px 0;
          font-size: 14px;
          line-height: 21px;
          color: #6b6c7e;
          strong {
            color: #272833;
            font-weight: 400;
            padding-left: 2px;
          }
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 4px;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #e7e7ed;
          }
        }
      }
      &__img-holder {
        td {
          padding: 0 !important;
          border: 0;
        }
        img {
          height: 40px;
          width: 40px;
          border-radius: 4px;
          object-fit: cover;
          max-width: 100%;
          overflow: hidden;
          margin: 0;
          margin-right: 4px;
        }
      }
    }
  }
  .modal-wrapper {
    .modal-inner {
      max-width: 395px;
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50;
      transform: translate(-50%, -50%);
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
      border-radius: 4px;
      z-index: 1100;
    }
    .pad-24.ledger-modal-body {
      max-height: 350px;
      overflow: overlay;
    }
  }
  .config-panel-contents,
  .grn-form {
    .info {
      label {
        font-size: 14px;
        color: #272833;
        font-weight: 600;
      }
    }
    .payment-mode {
      .zindex-2__control {
        height: 40px;
        min-height: 40px;
      }
    }
    .info.payment-mode {
      h3 {
        margin: 0;
        font-size: 14px;
        color: #272833;
        font-weight: 600;
        margin-bottom: 12px;
      }
      .wrapper {
        display: flex;
        flex-direction: row;
        gap: 16px;
        span {
          text-transform: capitalize;
        }
      }
      .ledger-info {
        label {
          font-weight: 400;
        }
      }
    }
  }
  .mb-24-required {
    margin-bottom: 24px !important;
  }
`;
export default SRNStyled;
