import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import withLoading from '../../../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  CustomSelect, Form, Input, SingleDatePicker
} from '../../../../../components';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';
import GRNFormStyled from './GRNFormStyled';
import BinLocation from './BinLocation';
import GRNDamageImageUpload from './GRNDamageImageUpload';

const propTypes = {
  uploadCallBack: PropTypes.func,
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  handleMultipleUpload: PropTypes.func,
  onUploadRemove: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  skuList: PropTypes.instanceOf(Array),
  selectedSkuTags: PropTypes.instanceOf(Array),
  refsObj: PropTypes.instanceOf(Object),
  skuBatchList: PropTypes.instanceOf(Array),
  getState: PropTypes.func,
  updateState: PropTypes.func,
  distributorBatchFlag: PropTypes.bool,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  skuList: [],
  skuBatchList: [],
  selectedSkuTags: [],
  enableErrorDisplay: true,
  distributorBatchFlag: false,
  uploadCallBack: () => null,
  onUploadRemove: () => null,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  handleMultipleUpload: () => null,
  getState: () => null,
  updateState: () => null,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const GRNForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    skuList,
    getState,
    updateState,
    skuBatchList,
    uploadCallBack,
    onUploadRemove,
    dropDownCallBack,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleMultipleUpload,
    selectedSkuTags,
    distId,
    inputCallBack,
    getImages,
    type,
    pendingStatus,
    distributorBatchFlag,
    handleQntyChange,
    exciseInLine,
  } = props;
  const binTotalQty = data?.warehouseLevelDetails?.map?.((d) => d?.quantity).reduce((a, b) => a + b, 0) ?? 0;
  return (
    <GRNFormStyled>
      <Form>
        <div className={update.type === EVENT_OPERATION.APPROVE ? 'scrollable-body-vertical' : ' '}>
          <div className='body_wrap'>
            <Row>
              <Col md={10}>
                <CustomSelect
                  enableValidation
                  disabled={update.type === EVENT_OPERATION.APPROVE}
                  options={skuList}
                  labelContent='SKU'
                  className='custom-select'
                  placeholder='Select SKU'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  ref={(ref) => (refsObj.skuId = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => handleDropDownChange(event.id, ['skuId'], dropDownCallBack)}
                  value={skuList.filter(({ id }) => id === data.skuId)}
                />
              </Col>
            </Row>
            <Row>
              <Col md className={skuBatchList?.filter((a)=>a.isBlocked)?.length>0?'audit-blocked':''}>
                <CustomAutoComplete
                  name='batchName'
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => handleInputChange(event, null, '', inputCallBack)}
                  reflectOnOutputChange={getState().batchName === ''}
                  localDataInputChange
                  dropDownList={skuBatchList}
                  labelContent='Batch'
                  placeholder='Batch'
                  dropDownValueKey='id'
                  dropDownDisplayKey='batchName'
                  searchText={data.batchName}
                  disabled={!distributorBatchFlag || !data.skuId || update.type === EVENT_OPERATION.APPROVE || skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
                  controlledInput={!distributorBatchFlag}
                  refs={refsObj}
                  onDropDownSelection={(event) => handleDropDownChange(event, ['skuBatchId'], dropDownCallBack)}
                />
              </Col>
              <Col md>
                <div className='single-date-picker one'>
                  <label>Manufacturing (MFG) Date</label>
                  <SingleDatePicker
                    name='manufactureDate'
                    date={data.manufactureDate}
                    disabled={update.type === EVENT_OPERATION.APPROVE}
                    onChange={(name, date) =>
                      handleInputChange({
                        target: {
                          name: 'manufactureDate',
                        },
                        formattedValue: date,
                      })
                    }
                    maxDate={data.expiryDate}
                  />
                </div>
              </Col>
              <Col md>
                <div className='single-date-picker two'>
                  <label>Expiry (EXP) Date</label>
                  <SingleDatePicker
                    name='expiryDate'
                    date={data.expiryDate}
                    disabled={update.type === EVENT_OPERATION.APPROVE}
                    onChange={(name, date) =>
                      handleInputChange({
                        target: {
                          name: 'expiryDate',
                        },
                        formattedValue: date,
                      })
                    }
                    minDate={data.manufactureDate}
                  />
                </div>
              </Col>
              {selectedSkuTags && selectedSkuTags.includes('Food') && (
                <Col md className=''>
                  <div className='best-before-date'>
                    <Input
                      name='bestBefore'
                      type='number'
                      labelContent='Best Before (in Months)'
                      placeholder='Best Before'
                      argument={{ min: 1 }}
                      value={data.bestBefore}
                      disabled={update.type === EVENT_OPERATION.APPROVE}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row className='mt-24'>
              <Col md={3}>
                <Input
                  name='quantity'
                  type='number'
                  rule='isInt'
                  labelContent='Quantity (QTY)'
                  enableValidation
                  value={data.quantity}
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (refsObj.quantity = ref)}
                  onChange={(event) => handleInputChange(event)}
                  disabled={update.type === EVENT_OPERATION.APPROVE}
                  argument={{
                    min: 1,
                    allow_leading_zeroes: false,
                  }}
                  errorMessage={data.quantity ? INVALID : EMPTY}
                />
              </Col>
              <Col md={3}>
                <Input
                  name='rate'
                  type='number'
                  rule='isFloat'
                  labelContent='Rate'
                  enableValidation
                  value={data?.priceDetails?.rate}
                  decimalWithPrecedingZero
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (refsObj.rate = ref)}
                  disabled={update.type === EVENT_OPERATION.APPROVE}
                  onChange={(event) => handleInputChange(event, 'priceDetails')}
                  errorMessage={data?.priceDetails?.rate ? INVALID : EMPTY}
                />
              </Col>
              <Col md={3}>
                <Input
                  name='discount'
                  type='number'
                  labelContent='Discount'
                  enableValidation
                  rule='isFloat'
                  argument={{
                    min: 0,
                    max: data?.priceDetails?.rate * data?.quantity,
                  }}
                  decimalWithPrecedingZero
                  value={data?.priceDetails?.discount}
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (refsObj.discount = ref)}
                  disabled={update.type === EVENT_OPERATION.APPROVE}
                  onChange={(event) => handleInputChange(event, 'priceDetails')}
                  errorMessage={data?.priceDetails?.discount ? INVALID : EMPTY}
                />
              </Col>
              {exciseInLine && (
                <Col md={3}>
                  <Input
                    name='exciseAmount'
                    type='number'
                    labelContent='Excise'
                    rule='isFloat'
                    decimalWithPrecedingZero
                    value={data?.priceDetails?.exciseAmount}
                    onChange={(event) => handleInputChange(event, 'priceDetails')}
                    disabled={update.type === EVENT_OPERATION.APPROVE}
                  />
                </Col>
              )}
            </Row>
          </div>
          <div className='bin__location body_wrap'>
            <BinLocation
              update={update}
              enableErrorDisplay={enableErrorDisplay}
              handleDropDownChange={handleDropDownChange}
              handleInputChange={handleInputChange}
              data={data}
              updateState={updateState}
              distId={distId}
              refsObj={refsObj}
            />
          </div>
          <div className='body_wrap'>
            <Row>
              <Col md={3}>
                <Input
                  rule='isInt'
                  type='number'
                  name='shortages'
                  labelContent='Shortage'
                  value={data?.shortages}
                  ref={(ref) => (refsObj.shortages = ref)}
                  enableValidation
                  onChange={(event) =>
                    pendingStatus ? handleInputChange(event, '', [], handleQntyChange) : handleInputChange(event)
                  }
                  argument={{
                    min: 0,
                    max: data?.quantity - binTotalQty,
                    allow_leading_zeroes: false,
                  }}
                  enableErrorDisplay={
                    (data?.quantity - binTotalQty >= 0 && data?.shortages > data?.quantity - binTotalQty) ||
                    enableErrorDisplay
                  }
                  errorMessage={
                    data?.quantity - binTotalQty >= 0 && data?.shortages > data?.quantity - binTotalQty
                      ? `Must not be greater than ${data?.quantity - binTotalQty} quantity`
                      : ''
                  }
                  disabled={data?.quantity === 0} // all validation is based on the  main quantity minus binTotal Quantity
                />
              </Col>
              <Col md={3}>
                <Input
                  rule='isInt'
                  name='damages'
                  type='number'
                  labelContent='Damages'
                  ref={(ref) => (refsObj.damages = ref)}
                  value={data.damages}
                  enableValidation
                  onChange={(event) =>
                    pendingStatus ? handleInputChange(event, '', [], handleQntyChange) : handleInputChange(event)
                  }
                  argument={{
                    min: 0,
                    max: data.quantity - data?.shortages,
                    allow_leading_zeroes: false,
                  }}
                  enableErrorDisplay={
                    (data.quantity - data?.shortages >= 0 &&
                      data.damages > Math.abs(data.quantity - data?.shortages)) ||
                    enableErrorDisplay
                  }
                  errorMessage={
                    data.quantity - data?.shortages >= 0 && data.damages > Math.abs(data.quantity - data?.shortages)
                      ? `Must not be greater than ${data.quantity - data?.shortages} quantity`
                      : ''
                  }
                  disabled={data?.quantity === 0} // all validation is based on the main quantity -shortage quantity
                />
              </Col>

              {data.damages ? (
                <GRNDamageImageUpload images={data.damageImages} getImages={(childMethod) => getImages(childMethod)} />
              ) : (
                ''
              )}
            </Row>
          </div>
        </div>
      </Form>
    </GRNFormStyled>
  );
};

GRNForm.propTypes = propTypes;

GRNForm.defaultProps = defaultProps;

const GRNFormWithErrorAndLoading = withLoading(GRNForm);

export default GRNFormWithErrorAndLoading;
