import { RETURN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { totalPriceMapper } from '../create/config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { getTodaysDate } from '../../../../utils/date';
import LEDGERS from '../../../../data/enums/Ledgers';
import { PAYMENT_MODE } from '../../orderProcessing/received/salesInvoice/customerDetail/config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'D&E Return',
    path: `/${RETURN}`,
    active: true,
  },
];

const orderFormatter = (orders = []) => {
  const updatedOrders = orders.map(item => ({
    ...item,
    returnQuantity: item.quantity,
  }));

  return updatedOrders;
};

const dataMapper = (data = {}) => ({
  id: data.id || null,
  srnInvoiceNumber: data.SRNInvoiceNumber || '',
  lines: data.SRNDetails || [],
  salesInvoiceNumber: data.invoiceNumber || '',
  srnAmount: data.srnAmount,
  Distributor: data.Distributor || { serviceUsed: {} },
  cashStatus: data.paymentMode === LEDGERS.CASH.title,
  reason: data.reason || '',
  paymentMode:
    data?.paymentMode === PAYMENT_MODE?.CREDIT_ROSIA ? PAYMENT_MODE?.CREDIT_BILLING : data?.paymentMode ?? null,
  srnDate: data.srnDate || getTodaysDate(),
  isOldBill: data.isOldBill,
  Customer: {
    id: data.Customer ? data.Customer.id : null,
    name: data.Customer ? data.Customer.name : '',
    address: data.Customer ? data.Customer.address : '',
    panNumber: data.Customer ? data.Customer.panNumber : '',
  },
  RetailOutlet: {
    id: data.RetailOutlet ? data.RetailOutlet.id : '',
    title: data.RetailOutlet ? data.RetailOutlet.title : '',
    Customers: data.RetailOutlet ? data.RetailOutlet.Customers : [],
  },
  customerName: data.customerName || '',
  orders: orderFormatter(data.SRNDetails) || [],
  billDiscountDetails: [],
  amount: totalPriceMapper(data.srnAmount ? data.srnAmount || {} : {}),
});

const getFormattedSkuLines = (list, oldBillStatus) =>
  list.map(item => {
    const { amount, ...srnLineAmount } = item.amountDetails;
    return {
      id: item.id,
      orderId: oldBillStatus ? null : item.orderId,
      skuId: item.skuId,
      skuBatchId: item.skuBatchId,
      batchName: item.SKUBatch.batchName,
      stockType: item.stockType,
      returnQuantity: item.returnQuantity,
      quantity: item.quantity,
      per_unit_rate: item.per_unit_rate || null,
      srnLineAmount: {
        ...srnLineAmount,
        rate: Number(srnLineAmount.rate),
        discountAmount: Number(srnLineAmount.discountAmount),
        promotionDiscount: Number(srnLineAmount.promotionDiscount),
      },
    };
  });

const customerId = data => {
  const isBillingUser = JSON.parse(localStorage.getItem('isBillingUser'));
  const { details } = data;
  const paymentMode = details?.paymentMode || PAYMENT_MODE.CASH;
  if (!isBillingUser) {
    return details?.Customer?.id || null;
  }
  if ([PAYMENT_MODE.CASH, PAYMENT_MODE.COD].includes(paymentMode)) {
    return details?.Customer?.id || LEDGERS?.[paymentMode]?.ledger_id;
  }
  return details?.Customer?.id;
};

const getPaymentMode = data => {
  const isBillingUser = JSON.parse(localStorage.getItem('isBillingUser'));
  const { CREDIT, CREDIT_ROSIA, CREDIT_BILLING } = PAYMENT_MODE;
  const { details } = data;
  if (isBillingUser) {
    return details?.paymentMode === CREDIT_BILLING ? CREDIT_ROSIA : details?.paymentMode ?? CREDIT;
  }
  return details?.cashStatus === true ? LEDGERS.CASH.title : CREDIT;
};
const getPayload = data => ({
  invoiceNumber: data.details.salesInvoiceNumber,
  srnInvoiceNumber: data.details.srnInvoiceNumber,
  reason: data.details.reason,
  distributorId: data.details.Distributor.id,
  retailOutletId: data.details.RetailOutlet.id,
  customerId: customerId(data),
  isOldBill: data.isOldBill,
  paymentMode: getPaymentMode(data),
  srnLines: getFormattedSkuLines(data.orders, data.oldBillStatus),
  srnDate: data.details.srnDate,
  srnAmount: {
    ...data.amount,
    discountAmount: Number(data.amount.discountAmount),
    promotionDiscount: Number(data.amount.promotionDiscount),
    billDiscount: Number(data.amount.billDiscount),
    tradeDiscount: Number(data.amount.tradeDiscount),
  },
  type: data.srnType,
});

const crudSuccess = {
  [EVENT_OPERATION.RECONCILED]: {
    objectMapper: data => ({
      id: data.id,
      input: getPayload(data),
    }),
    responseName: 'reconcileReturnRequest',
    message: `Request ${MESSAGE_EVENT_OPERATION.RECONCILED}`,
  },
  [EVENT_OPERATION.REJECTED]: {
    objectMapper: data => ({ id: data.id }),
    responseName: 'rejectReturnRequest',
    message: ' D&E rejected successfully!',
  },
};
export { breadCrumb, crudSuccess as crudRequestConfig, dataMapper };
