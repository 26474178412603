import React from 'react';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  tableData, defaultMenuConfigList, menuAction
} from '../../../../../v4/components';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { normalPresentor } from '../../../../../utils/date';

const labelMappings = {
  sku: 'sku',
  batch: 'batch',
  mfgDate: 'mfgDate',
  expDate: 'expDate',
  shortageAndDamage: 'shortageAndDamage',
  quantity: 'quantity',
  rate: 'rate',
  amount: 'amount',
  discount: 'discount',
  netAmount: 'netAmount',
  status: 'status',
  exciseAmount: 'exciseAmount',
};

const labelConfig =(exciseInLine)=>( [
  {
    id: 1,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.batch,
    title: 'Batch',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.mfgDate,
    className: 'text-center',
    title: 'MFG Date',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.expDate,
    className: 'text-center',
    title: 'EXP Date',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.shortageAndDamage,
    title: 'S/D',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.quantity,
    title: 'Quantity',
    className: 'text-right',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.rate,
    className: 'text-right',
    title: 'Rate',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.amount,
    className: 'text-right',
    title: 'Amount',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.discount,
    className: 'text-right',
    title: 'Discount',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.exciseAmount,
    className: 'text-right',
    title: 'Excise',
    show: exciseInLine,
  },
  {
    id: 11,
    label: labelMappings.netAmount,
    className: 'text-right',
    title: 'Net Amount',
    show: true,
  },
  {
    id: 12,
    label: labelMappings.status,
    title: '',
    show: true,
  },
]);

const TableHeader = (exciseInLine) => (
  <thead className=''>
    <tr>{labelConfig(exciseInLine)?.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const menuConfigList = [defaultMenuConfigList[EVENT_OPERATION.UPDATE], defaultMenuConfigList[EVENT_OPERATION.DELETE]];

const returnTableBody = (data, onIconClick, update, exciseInLine) => {
  const table = {};
  table[labelMappings.sku] = <td key={`${data.skuId}-sku`}>{data.SKU.title}</td>;
  table[labelMappings.batch] = <td key={`${data.skuId}-batch`}>{data.batchName || data.SkuBatch.batchName}</td>;
  table[labelMappings.mfgDate] = (
    <td key={`${data.skuId}-mfgDate`} className='text-center'>
      {normalPresentor(data.manufactureDate || data.SkuBatch.manufactureDate) === 'Invalid date'
        ? '-'
        : normalPresentor(data.manufactureDate || data.SkuBatch.manufactureDate)}
    </td>
  );
  table[labelMappings.expDate] = (
    <td key={`${data.skuId}-expDate`} className='text-center'>
      {normalPresentor(data.expiryDate || data.SkuBatch.expiryDate) === 'Invalid date'
        ? '-'
        : normalPresentor(data.expiryDate || data.SkuBatch.expiryDate)}
    </td>
  );
  table[labelMappings.shortageAndDamage] = (
    <td key={`${data.skuId}-shortageAndDamage`}>{`${data.shortages || 0}/${data.damages || 0}`}</td>
  );
  table[labelMappings.quantity] = (
    <td key={`${data.skuId}-quantity`} className='text-right'>
      {data.quantity}
    </td>
  );
  table[labelMappings.rate] = (
    <td key={`${data.skuId}-rate`} className='text-right'>
      {fixedFloatAndCommas(data.priceDetails.rate || 0)}
    </td>
  );
  table[labelMappings.amount] = (
    <td key={`${data.skuId}-amount`} className='text-right'>
      {fixedFloatAndCommas(data.priceDetails.amount || 0)}
    </td>
  );
  table[labelMappings.discount] = (
    <td key={`${data.skuId}-discount`} className='text-right'>
      {fixedFloatAndCommas(data.priceDetails.discount || 0)}
    </td>
  );
  table[labelMappings.exciseAmount] = (
    exciseInLine ? <td key={`${data.skuId}-exciseAmount`} className='text-right'>
      {fixedFloatAndCommas(data.priceDetails.exciseAmount || 0)}
    </td>:null
  );
  table[labelMappings.netAmount] = (
    <td key={`${data.skuId}-netAmount`} className='text-right'>
      {fixedFloatAndCommas(data.priceDetails.netAmount)}
    </td>
  );
  table[labelMappings.status] = update.status ? (
    <td key={`${data.skuId}-status`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, {
        update: true,
        delete: update.type === EVENT_OPERATION.UPDATE,
      })}
    </td>
  ) : (
    <td key={`${data.skuId}-nostatus`} />
  );

  return table;
};

const TableBody = (data, onIconClick, update, exciseInLine) => {
  const tableBody = returnTableBody(data, onIconClick, update, exciseInLine);

  return <tr key={data.skuId}>{labelConfig(exciseInLine)?.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

export { TableHeader, TableBody };
