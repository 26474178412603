
const getEnumFromObject = (object) => Object.freeze({ ...object });

const PROGRAM = getEnumFromObject({
  BILLING_PLATFORM: 'Billing Platform',
  BU_SETTINGS: 'BU Settings',
  CASH: 'Cash',
  CATALOG: 'Catalog',
  CHANNEL_CATEGORY: 'Channel/Category',
  CHEQUE: 'Cheque',
  FOCUSED_SKU: 'Focused SKU',
  FUNDAMENTAL_REPORTS: 'Fundamental Reports',
  GRN: 'GRN',
  LEAVE_REQUEST: 'Leave Request',
  MERCHANDISING_DEVICE: 'Merchandising Device',
  OPENING_BALANCE: 'Opening Balance',
  ORDER_FULLFILLMENT: 'Order Fulfillment',
  ORDER_RECEIVE: 'Order Receive',
  OUTLET: 'Outlet',
  OUTLET_RECOMENDATION: 'Outlet Recomendation',
  OUTSTANDING_DUES: 'Outstanding Dues',
  PAYMENT_RECEIVE: 'Payment Recieve',
  POST_DATES_CHEQUE: 'Post Dates Cheque',
  PROMOTION: 'Promotion',
  PURCHASE_RETURN: 'Purchase Return',
  PURCHASE_ORDER: 'Purchase Order',
  RECEIPT: 'Receipt',
  ROSTER: 'Call Roster',
  ROUTE: 'Route',
  SALES_RETURN: 'Sales Return',
  RETURNS: 'D&E Returns',
  SBD: 'SBD',
  SBD_FOCUSED_SKU: 'SBD & Focused SKU',
  SKU: 'SKU',
  SKU_GROUP: 'SKU',
  STOCK_ADJUSTMENT: 'Stock Adjustment',
  STOCK_LEDGER: 'Stock Ledger',
  SUB_D: 'Sub-D',
  TARGET_ACHIEVEMENT: 'Target & Achievement',
  TERRITORY: 'Territory',
  TOWN: 'Town',
  USER_ACCOUNT: 'Users',
  USER_GROUP: 'User Group',
  ROUTE_SEGMENTATION: 'Route Segmentation',
  PSR: 'Reports',
  PRODUCTIVITY: 'Reports',
  SUBD_ASSORTMENT: 'Distributor Assortment',
  CHANNEL_ASSORTMENT: 'Channel Assortment',
  SUBD_REPLENISHMENT: 'Replenishment Report',
  USER_CLUSTER: 'User Cluster',
  APPROVAL_REQUEST: 'Approval Request',
  PROMOTIONAL_SKU: 'promotionalsku',
  TRADE:'TRADE',
  DOWNLOAD_USER_ACCOUNT:'User Account',
  DOWNLOAD_USER_HIERARCHY: 'User Hierarchy',
  PUSH_MESSAGE: 'Push Message',
  SYSTEM_ALERT: 'System Alert',
  PRESENTATION: 'Presentation',
  LOCATION_HIERARCHY: 'Location Hierarchy',
  ARC: 'ARC',
  CLASSIFICATION: 'Classification',
  CALL_PLAN: 'Call Plan',
  SPEND_CONTROL: 'Spend Control',
  COLLECTION: 'Collection',
  ATTENDANCE: 'Attendance',
  TARGETS: 'Target & Achievment',
  WAREHOUSE: 'Warehouse',
  ICO: 'ICO',
  REPLENISHMENT: 'Replenishment',
  DESIGNATIONS: 'Designations',
  TRIP_WISE_COLLECTION:'Trip Wise Collection',
  TARGET_DISTRIBUTION:'Target Distribution',
  SBD_TARGET:'SBD Target',
  SKU_GROUP:'SKU Group',
  ICO_GROUP:'ICO Group',
  LINKED_DEVICE:'Linked Device',
});

const PERMISSION_OBJ = {
  read: true,
  create: true,
  update: true,
  delete: true,
  approve: false,
};
export { PROGRAM, PERMISSION_OBJ };
