import { ChangeEvent } from 'react';
import { Button, CheckBox, Icon, Menu } from '../index';
import EVENT_OPERATION from '../../../data/enums/EventOperation';

export const tableData = (
  label: { title: string; align: string; classNames: string; dynamicTitle: boolean; id: any },
  newTitleObj: any,
) => {  
  return (
    <th key={label.title} className={`${label.align ? 'text-right' : ''} ${label.classNames ? label.classNames : ''}`}>
      {label.dynamicTitle ? newTitleObj[label.id] : label.title}
    </th>
  );
};

export const tableHeaderWithCheckBox = (
  dataListLength: number,
  checkedListLength: number,
  primaryCheckboxHandler: (event: ChangeEvent<HTMLInputElement>) => void,
  permission: { delete: boolean },
  labelConfig: any[],
  labelMappings: any,
  newTitleObj: any,
) => {
  return (
    <thead>
      <tr>
        {labelConfig.map(label =>
          label.show
            ? label.label === (labelMappings.checkbox || 'checkbox')
              ? dataListLength > 0 &&
              permission.delete && (
                <th key={label.id} className="checkbox">
                  <CheckBox
                    checked={dataListLength === checkedListLength}
                    onCheckBoxClick={e => primaryCheckboxHandler(e)}
                  />
                </th>
              )
              : tableData(label, newTitleObj)
            : null,
        )}
      </tr>
    </thead>
  );
};

export const defaultMenuConfigList = {
  [EVENT_OPERATION.UPDATE]: {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  [EVENT_OPERATION.DELETE]: {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
  [EVENT_OPERATION.REPLICATE]: {
    title: 'Replicate',
    icon: 'copy',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.REPLICATE,
  },
  [EVENT_OPERATION.REPLACE]: {
    title: 'Replace',
    icon: '',
    permissionDerivedBy: 'replace',
    type: EVENT_OPERATION.REPLACE,
  },
};

export const menuAction = (
  menuConfigList: any,
  headerConfig: any,
  onMenuItemClick: any,
  data: any,
  permission: any,
  withOutIcon: boolean,
  pageHeader: boolean,
) => {
  const menuHeader = <Button secondary={pageHeader} iconBtnSmall={pageHeader} iconName={headerConfig.menuIcon} />;
  return (
    <>
      <Menu label="" header={menuHeader}>
        <div className="filter-content text-left">
          <ul>
            {menuConfigList.reduce((acc: any, item: any) => {
              if (permission[item.permissionDerivedBy || 'read']) {
                acc.push(
                  <li
                    key={item.title}
                    onClick={e => {
                      e.preventDefault();
                      onMenuItemClick(item.type, data, () => null);
                    }}
                  >
                    <span>
                      {withOutIcon ? null : <Icon iconName={item.icon} />}
                      {item.title}
                    </span>
                  </li>,
                );
              }

              return acc;
            }, [])}
          </ul>
        </div>
      </Menu>
    </>
  );
};
