import React from 'react';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { tableData } from '../../../utils/paginatedListUtils';
import { menuAction } from '../../../v4/components';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { normalPresentor } from '../../../utils/date';
import TableHeader from '../../base/TableHeader';

// todo update the configurations.
const labelMappings = {
  id: 'id',
  date: 'createdAt',
  grnDate: 'grnDate',
  invoiceNumber: 'invoiceNumber',
  vendor: 'vendor',
  distributor: 'distributor',
  quantity: 'quantity',
  discount: 'billDiscount',
  amount: 'total',
  shortageAndDamage: 'shortageAndDamage',
  remarks: 'remarks',
  proofOfDelivery:'proofOfDelivery',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Cancel',
    icon: 'times-circle',
    permissionDerivedBy: 'cancel',
    type: EVENT_OPERATION.CANCEL,
  }
];

const returnTableBody = (data,onIconClick,permission) => {
  const table = {};
  table[labelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[labelMappings.date] = (
    <td key={`${data.id}-vendorInvoicedDate`}>{`${normalPresentor(data.invoiceDate)}/${
      data.invoiceTime ? data.invoiceTime : '-'
    }`}</td>
  );
  table[labelMappings.grnDate] = <td key={`${data.id}-grnDate`}>{normalPresentor(data.grnDate)}</td>;
  table[labelMappings.vendorInvoiceNumber] = <td key={`${data.id}-vendorInvoiceNumber`}>{data.vendorInvoiceNumber}</td>;
  table[labelMappings.invoiceNumber] = (
    <td style={{ whiteSpace: 'noWrap' }} key={`${data.id}-invoiceNumber`}>
      {data.invoiceNumber}
    </td>
  );
  table[labelMappings.vendor] = <td key={`${data.id}-vendorName`}>{data.Vendor.name}</td>;
  table[labelMappings.distributor] = <td key={`${data.id}-distributor`}>{data.Distributor.title}</td>;
  table[labelMappings.discount] = (
    <td key={`${data.id}-discount`} className='text-right'>
      {fixedFloatAndCommas(data.amount.tradeDiscount + data.amount.billDiscount || 0)}
    </td>
  );
  table[labelMappings.amount] = (
    <td key={`${data.id}-amount`} className='text-right'>
      {fixedFloatAndCommas(data.amount.total || 0)}
    </td>
  );
  table[labelMappings.shortageAndDamage] = (
    <td key={`${data.id}-damage`}>{`${data.shortages || 0}/${data.damages || 0}`}</td>
  );
  table[labelMappings.remarks] = <td key={`${data.id}-remarks`}>{data.remarks}</td>;

  table[labelMappings.proofOfDelivery] = (
    (!data.isManual) ? <td key={`${data.id}-proofOfDelivery`}>{
      data.proofOfDelivery?<img src='/image/icons/true-proof-of-delivery.svg'/>:<img src='/image/icons/false-proof-of-delivery.svg'/>
    }</td> : <td/>
  );

  table[labelMappings.actionHolders] = (
    <td key={`levels-${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const commonLabel = [
  {
    id: 6,
    label: labelMappings.vendor,
    title: 'Vendor',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 7,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 8,
    label: labelMappings.discount,
    title: 'Discount',
    align: true,
    show: true,
    sortable: true,
    className: 'text-right sorting',
  },
  {
    id: 9,
    label: labelMappings.amount,
    title: 'Amount',
    align: true,
    show: true,
    sortable: true,
    className: 'text-right sorting',
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'GRN ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Vendor Invoiced Date/Time',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.vendorInvoiceNumber,
    title: 'Vendor Invoice Number',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  ...commonLabel,
  {
    id: 9,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 10,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
    sortable: false,
  },
];

const grnLabelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'GRN ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Vendor Invoiced Date/Time',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.grnDate,
    title: 'GRN Date',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.vendorInvoiceNumber,
    title: 'Vendor Invoice Number',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 5,
    label: labelMappings.invoiceNumber,
    title: 'GRN Number',
    show: true,
  },
  ...commonLabel,
  {
    id: 9,
    label: labelMappings.shortageAndDamage,
    title: 'S/D',
    show: true,
    sortable: false,
    className: '',
  },
  {
    id: 10,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 11,
    label: labelMappings.proofOfDelivery,
    title: '',
    show: true,
    sortable: false,
  },
  {
    id: 12,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
    sortable: false,
  },
];

const TableBody = (data, onIconClick, sapEnabled, approvedGrn, billingEnabled,permission) => {
  const tableBody = returnTableBody(data,onIconClick,permission);
  return (
    <tr
      key={data.id}
      onClick={() =>
        sapEnabled || billingEnabled
          ? onIconClick(EVENT_OPERATION.APPROVE, data, 'pending')
          : onIconClick(EVENT_OPERATION.UPDATE, data)
      }
    >
      {(approvedGrn ? grnLabelConfig : labelConfig).map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeaderConfig = (onHeaderClick, approvedGrn) => (
  <TableHeader headerDetails={approvedGrn ? grnLabelConfig : labelConfig} onHeaderClick={onHeaderClick} />
);

export { TableHeaderConfig as TableHeader, TableBody };
