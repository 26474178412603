import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { dataProps } from '../../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { Input } from '../../../../../components';
import { Button } from '../../../../../v4/components';
import { INVALID } from '../../../../../data/enums/ErrorMessage';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { Col } from '../../../../../components/Grid';
import GRNDetailTable from './GRNDetailTable';
import { TableHeader, TableBody } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  skuLines: PropTypes.instanceOf(Array),
  update: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  priceDetails: PropTypes.instanceOf(Object),
  onInputChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  skuLines: [],
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  enableErrorDisplay: false,
  priceDetails: {},
  onInputChange: () => null,
  refsObj: {},
};

const TableView = ({ ...props }) => {
  const { update, onIconClick, skuLines, exciseInLine } = props;

  return (
    <GRNDetailTable>
      <div className={`table-wrap ${!update.status ? 'disabled' : ''}`}>
        <table>
          {TableHeader(exciseInLine)}
          <tbody>{skuLines.map((sku) => TableBody(sku, onIconClick, update, exciseInLine))}</tbody>
        </table>
        {update.status && (
          <div className='grn-sku-add'>
            <Button
              iconBtnSmall
              secondary
              iconName='plus'
              disabled={update.type === EVENT_OPERATION.APPROVE}
              onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
            />
          </div>
        )}
      </div>
    </GRNDetailTable>
  );
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableView);

const TotalView = ({ ...props }) => {
  const { update, refsObj, enableErrorDisplay, priceDetails, onInputChange } = props;

  return (
    <Fragment>
      <div className={!update.status ? 'disabled' : ''}>
        <div className='grn-sku-update'>
          <div className='grn-sku-update-inner'>
            <Row>
              <Col md={6}>
                <span>Sub Total</span>
              </Col>
              <Col md={6}>
                <div>{fixedFloatAndCommas(priceDetails.subTotal)} </div>
              </Col>
              <Col md={6} className='mt-5'>
                <span>Bill Discount</span>
              </Col>
              <Col md={6}>
                <Input
                  name='billDiscount'
                  type='number'
                  enableValidation
                  rule='isFloat'
                  ref={(ref) => (refsObj.billDiscount = ref)}
                  argument={{
                    min: 0,
                    max: priceDetails.subTotal - priceDetails.tradeDiscount,
                  }}
                  value={priceDetails.billDiscount}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event)}
                  disabled={update.type === EVENT_OPERATION.APPROVE}
                  errorMessage={INVALID}
                  decimalWithPrecedingZero
                />
              </Col>
              <Col md={6} className='mt-5'>
                <span>Trade Discount</span>
              </Col>
              <Col md={6}>
                <Input
                  name='tradeDiscount'
                  type='number'
                  enableValidation
                  rule='isFloat'
                  argument={{
                    min: 0,
                    max: priceDetails.subTotal - priceDetails.billDiscount,
                  }}
                  ref={(ref) => (refsObj.tradeDiscount = ref)}
                  value={priceDetails.tradeDiscount}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event)}
                  disabled={update.type === EVENT_OPERATION.APPROVE}
                  errorMessage={INVALID}
                  decimalWithPrecedingZero
                />
              </Col>
              <Col md={6}>
                <span>Taxable Amount</span>
              </Col>
              <Col md={6}>
                <div> {fixedFloatAndCommas(priceDetails.taxableAmount)} </div>
              </Col>
              <Col md={6}>
                <span>VAT</span>
              </Col>
              <Col md={6}>
                <div>{fixedFloatAndCommas(priceDetails.taxAmount)}</div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='grn-sku-update-total'>
          <span>TOTAL</span>
          <div>{fixedFloatAndCommas(priceDetails.total)}</div>
        </div>
      </div>
    </Fragment>
  );
};

export { TableViewWithErrorAndLoading as TableView, TotalView };

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

TotalView.propTypes = propTypes;

TotalView.defaultProps = defaultProps;

export default TableViewWithErrorAndLoading;
